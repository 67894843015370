/**
 * Created by duanjiwen on 2022/12/21.
 */

import {ToastType} from '../../common/toastList';
import NProgress from 'nprogress' // 引入nprogress插件
import 'nprogress/nprogress.css'  // 这个nprogress样式必须引入
import {Wrapper} from "./downLoad_style";

import React, {Component} from 'react';
import {$http, Qs, setTitle} from '../../tools';
import plist from 'plist';

import storeTop3 from './../../images/storeImages/storeTop3.png'
import top_bg_mobile from './../../images/top_bg_mobile.svg'
import {JSEncrypt} from "jsencrypt";
import axios from "axios";
import QRCode from "qrcode.react";
import {useTranslation, withTranslation} from 'react-i18next';

var params = Qs();
// import file from './embedded.mobileprovision'
const host = "https://dl.mlquilab.com"

class DownLoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasClick: false,
            showProfilModal: false,
            appName: "",
            appLogo: "",
            plistPath: "",
            curUrl:""
        }
    }

    componentDidMount() {
        setTitle('');
        this.getPlistPath()
    }

    getPlistPath() {
        params = Qs();
        let curUrl = window.location.href
        var appName=params.appName
        if(appName===undefined){
            appName = curUrl.substring(curUrl.lastIndexOf('/')+1)
        }

        var plistPath = `${host}/app/${appName}/info.plist`;

        this.setState({
            appName: appName,
            plistPath: plistPath,
            curUrl:curUrl
        })
        $http.get(`/app/${appName}/info.plist`, {}).then(res => {
            const parsedData = plist.parse(res);
            this.setState({
                appName: parsedData.items[0].metadata.title,
                appLogo: parsedData.items[0].assets[2].url,
            });
            const {i18n} = this.props;
            i18n.changeLanguage(parsedData.items[0].locale);
        });
    }

    startDowLoad() {
        const {t} = this.props;

        var ua = navigator.userAgent.toLowerCase();
        console.log(ua)

        //判断是不是在iPhone的Safair浏览器打开的本页面
        // if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
        //     ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
        //     ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1) {
            window.location.href = `itms-services://?action=download-manifest&url=${this.state.plistPath} `;

            this.setState({
                hasClick: true
            })
            ToastType('loading', t('installLoading'), null, {duration: 2000});

        // } else {
        //     ToastType('Toast', "Silakan gunakan browser \"Safari\" untuk membuka situs web ini",null,{});
        // }

    }

    startDownProfile() {
        NProgress.start()
        window.location.href = '/embedded.mobileprovision'

        setTimeout(() => {
            NProgress.done()
        }, 4000);

    }

    componentWillUnmount() {

    }


    render() {
        const {t} = this.props;

        return (
            <Wrapper>
                <div className="page flex-column-center">
                    <img className="top-bg" src={top_bg_mobile}/>
                    <img className="logo" src={this.state.appLogo}/>
                    <div className="app-name"
                    >{this.state.appName}</div>
                    <QRCode value={this.state.curUrl} className="qr-code"/>
                    <div className="download-btn" onClick={this.startDowLoad.bind(this)}>{t('downloadBtnTxt')}</div>
                    {
                        <div>
                            <div className="download-success-txt">1.{t('downloadTip1')}</div>
                            <div className="download-success-txt">2.{t('downloadTip2')}</div>
                            <div className="download-success-txt">3.{t('downloadTip3')}</div>
                        </div>
                    }
                </div>
            </Wrapper>
        );
    }
}

// Wrap the component with withTranslation HOC
export default withTranslation()(DownLoad);