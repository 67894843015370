import styled from 'styled-components'

export const Wrapper = styled.div`
    .page {
        .top-bg {
            width: 100%;
            height: auto;
        }

        .logo {
            margin-top: 20px;
            width: 120px;
            height: 120px;
            border-radius: 24px;
        }

        .app-name {
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 24px;
            color: #0a0a0a;
        }

        .qr-code {
            margin-top: 10px;
            width: 120px;
            height: 120px;
            padding: 3px;
        }

        .download-btn {
            margin-top: 30px;
            font-size: 16px;
            padding: 12px 70px;
            border-radius: 30px;
            color: #FFFFFF;
            background: #28caad;
        }

        .download-success-txt {
            text-align: left;
            margin-top: 10px;
            font-size: 14px;
            color: #bc1a3a;
            padding: 0 20px;
        }

        .download-trust-img {
            width: 100%;
            height: auto;
            padding: 10px 20px;
        }

    }


    .downLoad {
        .topDownLoad {

            .top1 {

            }

            .icon {
                width: 1.3rem;
                height: 1.3rem;
                margin-top: -13.7rem;
                margin-left: 7.05rem;
            }

            .iconGray {
                width: 4.3rem;
                height: 1.3rem;
                margin-top: -13.7rem;
                margin-left: 7.05rem;
            }
        }

        .top2 {
            margin-top: 12.2rem;
        }

        .alertModal {
            position: fixed;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.81);
        }

        .alertOne {
            margin-top: 10rem;
            padding: 0 2rem;
        }

        .alertTwo {
            margin-top: 1.75rem;
            padding: 0 2.6rem;
        }
    }

`