import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

i18n.use(LanguageDetector)
    .init({
        interpolation: {escapeValue: false}, // React 会自动转义
        lng: 'en', // 默认语言
        resources: {
            en: {
                translation: require('./locales/en.json')
            },
            zh: {
                translation: require('./locales/zh.json')
            },
            id: {
                translation: require('./locales/id.json')
            },
            es: {
                translation: require('./locales/es.json')
            }
        }
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>,
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
